import React from 'react';
// import { Item } from 'cms/items/item';
// import { SiteLink } from 'shared/link/siteLink';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { YoutubeEmbed } from 'components';

export function YoutubeEmbedded(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  // const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');

  const cssClass = utils.classNames('cms_item', 'youtubeEmbedded', item.cssClass || item.anchorName || '');

  //console.log(window.innerWidth, canPlayVideo())

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
     <YoutubeEmbed url={videoCode} />
    </div>
  );
}

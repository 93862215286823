import React from 'react';
import env from 'env';
import { usePost } from 'components';
import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';
import { getContactModel } from './contactFormModel';

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), emailReceiver: emailReceiver };
      post.send(env.apiBase + "/api/contact/send", value);
    })
    console.log('submitting')
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="contactForm">
      <div className="form-row">
        <div className="col-md-4 mb-2">{render('name')}</div>
        <div className="col-md-4 mb-2">{render('phone')}</div>
        <div className="col-md-4 mb-2">{render('email')}</div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-2">{render('enquiry')}</div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>SEND MESSAGE</Button>
            <ErrorMessage errors={post.errors} />
            {post.status === 'done' && 'Successfully sent!'}
          </div>
        </div>
      </div>
    </form>
  );
}
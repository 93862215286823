import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default{
    sliderItem : css({
        // maxWidth : '300px',
        // backgroundColor: '#f8f8ff',
        // opacity: '.9',
        // textAlign: 'center',
        // color: '#000',
        // padding: '10px',
        // //height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
        // margin: 'auto',
        // height: '430px'
        'img' : mq({
            //padding: ['0px 1rem', '0px 0px'],
            padding: '0px 0.5rem',
            margin : 'auto',
            maxWidth: '100%',
            height:'auto'
        })
    })
    // testimonialItem_title : css({
    //     //flex: '1 1 auto'
    //     marginBottom: '0px'
    // }),
    // testimonialItem_desc : css({
    //     flex: '1 1 auto'
    //     //flexGrow: '1',
    //     //flexShrink: '1'
    // })
}
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

function getPages(pagesSerialized) {
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
}
  
function Tile(props) {
    
    const page = props.page;
    const imageUrl = utils.site.resourcePath(page.imageUrl);
    const disableLink = props.disableLink;

    return (
      
      <div css = {style.pageTile__tile} className="pageTile__adore">
        <SiteLink className="pageTile__adore__content" css = {style.tile__content} to={disableLink ? '' : page.pageUrl}> 
         <div className="pageTile__adore__bg" css = {style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          
         <div css = {style.tile__details} className="pageTile__adore__content__details">
            <div css = {style.tile__details__title} className="pageTile__adore__title">
                {page.pageTitle}
            </div>
            <div css = {style.tile__details__desc} className="pageTile__adore__desc">
                {page.tileDesc || page.description}
            </div>
         </div>

        </SiteLink>
      </div>
    )
}

export function PageTileAdore(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
    const pageTileElts = pages.map((page, index) =>
        <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></Tile>
    );

    const cssClass = utils.classNames('cms_item', 'pageTiles__adore', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
     css = {style.pageTiles}>
      {pageTileElts}
    </div>
  );

}
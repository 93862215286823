import Slider from "react-slick";
import utils from 'utils';
//import cmsUtils from 'cms/utils/cmsUtils';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';

import { SliderItem } from '../sliderItem/view';

export function SliderPanel(props){

    const item = props.item;
    //const defaultDesH = 220;
    const subItems = item.items || [];
    //console.log(subItems.length);
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'sliderPanel', 'container', item.cssClass || item.anchorName || '');

    const sliderItems = subItems.map((subItem, index) => {
        return <SliderItem item={subItem} key={subItem.itemId} index = {index}></SliderItem>
    });

    var settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        autoplaySpeed: 3000,
        ////fade: true,
        autoplay: true,
        slidesToShow: (subItems.length >= 6 ? 6 : subItems.length),
        slidesToScroll: 1,
        responsive : [
            // {breakpoint : 1024, settings : { slidesToShow: (subItems.length >= 2 ? 2 : subItems.length) }},
            {breakpoint : 1024, settings : { slidesToShow: (subItems.length >= 3 ? 3 : subItems.length) }}
        ]
    };

    return(
        <div css = {style.sliderPanel} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <Slider {...settings}>
                { sliderItems }
            </Slider>
        </div>
    );

}
import React from 'react';

export function NotFound(props) {
  const debugInfo = props.debugInfo;

  function refreshPage(e) {
    e.preventDefault();
    window.location.reload();
  }

  function goBack(e) {
    e.preventDefault();
    window.history.back();
  }

  return (
    <div className="error-panel">
      <div className="container">
        <h1>404 Error</h1>

        <p>Please <a href="/" onClick={(e) => refreshPage(e)}>refresh</a> your browser.</p>
        <div>
          <button onClick={(e) => goBack(e)}>or Go Back</button>
        </div>

        {
          debugInfo &&
          <pre style={{ marginTop: '3rem', color: 'chocolate', fontSize: '0.7rem' }}
          >Error message: {debugInfo.pageUrl} - {debugInfo.loadFrom} </pre>
        }

      </div>

    </div>

  )
}
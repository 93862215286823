//import { Item } from 'cms/items/item';
import Slider from "react-slick";
import utils from 'utils';
//import cmsUtils from 'cms/utils/cmsUtils';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
//import { useState, useEffect } from 'react';

import { TestimonialItem } from '../testimonialItem/view';

export function TestimonialPanel(props){
    const item = props.item;
    //const defaultDesH = 220;
    const startRatingColor = props.startRatingColor;
    const subItems = item.items || [];
    //console.log(subItems.length);
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'testimonialPanel', item.cssClass || item.anchorName || '');
    
    //const [desH, setDesH] = useState(defaultDesH);

    //var maxDesTextLength = 0;
    const testimonialItems = subItems.map((subItem, index) => {
            // let subItemDes = cmsUtils.payload(subItem, "Desc");
            // if(maxDesTextLength < subItemDes.length){
            //     maxDesTextLength = subItemDes.length
            // }
            //return <TestimonialItem item={subItem} key={subItem.itemId} cmsOption={cmsOption} desH = {desH} startRatingColor = {startRatingColor} ></TestimonialItem>
            return <TestimonialItem item={subItem} key={subItem.itemId} cmsOption={cmsOption} startRatingColor = {startRatingColor} ></TestimonialItem>
        }
    );
    
    // useEffect(() => {
    //     if(defaultDesH < maxDesTextLength){
    //         setDesH(maxDesTextLength);
    //     }
    // }, [maxDesTextLength]);

    var settings = {
        dots: false,
        infinite: true,
        speed: 1700,
        autoplaySpeed: 3000,
        ////fade: true,
        autoplay: true,
        slidesToShow: (subItems.length >= 3 ? 3 : subItems.length),
        //slidesToShow: 3,
        slidesToScroll: 1,
        responsive : [
            {breakpoint : 1024, settings : { slidesToShow: (subItems.length >= 2 ? 2 : subItems.length) }},
            {breakpoint : 800, settings : { slidesToShow: 1 }}
        ]
    };

    return(
        <div css = {style.testimonialPanel} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <Slider {...settings}>
                { testimonialItems }
            </Slider>
        </div>
    );
}
const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"Gotham",sans-serif',
  familyHeader: '"DIN Next LT Pro",serif',

  primaryColor: '#30b0c5',
  fontColor: '#404040',
  bgColor: '#ebebeb',

  ...overloading
}

export default variables;
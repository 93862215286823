//import { CmsPageController } from './cms-page-controller'

export class CmsRadioSender {
  controller;//: CmsPageController;
  constructor(controller) {
    this.controller = controller;
  }

  sendMessageToParent(action: string, data: any) {
    var cloned = JSON.parse(JSON.stringify(data));
    window.parent.postMessage({
      action: action,
      data: cloned
    }, "*");
  }

  saveAllDirtyHtml(htmlChanges) {
    // skip another unneccesary round trip
    this.sendMessageToParent('return-all-dirty-html', {
      returnTo: '_saveAllDirtyHtml',
      htmlChanges: htmlChanges
    });
  }

  selectControllerItem(data) {
    this.sendMessageToParent("select-controller-item", data);
  }

  returnAllDirtyHtml(returnTo, htmlChanges) {
    this.sendMessageToParent('return-all-dirty-html', {
      returnTo: returnTo,
      htmlChanges: htmlChanges
    });
  }

  returnHtml(id, html) {
    this.sendMessageToParent('return-html', {
      id: id,
      html: html
    });
  }

  markDirtyHtml(id) {
    this.sendMessageToParent("mark-dirty-html-item", { id: id });
  }
}

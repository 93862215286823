import React from 'react';
// import { Item } from 'cms/items/item';
// import { SiteLink } from 'shared/link/siteLink';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { YoutubeBanner } from 'components';
//import env from 'env';
//import Slider from 'modules/slider/slider';
import {BannerPanel} from '../bannerPanel/view';

export function YoutubeBannerPanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }

  //const enableYoutube = env.isProd;
  //const canPlayVideo = () => window.innerWidth >= 1200
  const canPlayVideo = () => window.innerWidth >= 800

  const cssClass = utils.classNames('cms_item', 'youtubePanel', heightSet, item.cssClass || item.anchorName || '');

  //console.log(window.innerWidth, canPlayVideo())

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>}
      {/* <YoutubeBanner video={video} />
      {false && <MobileSlider item={item}></MobileSlider>} */}
    </div>
  );
}

// function MobileSlider(props) {
//   const item = props.item;
//   const subItems = item.items || [];
  
//   const slides = subItems.map((subItem, index) =>
//     <SlideItem item={subItem} key={subItem.itemId}></SlideItem>
//   );

//   useLayoutEffect(() => {
//     setTimeout(() => {
//       console.log(window.innerWidth);
//     const slider = new Slider('.contSlider');
//     slider.init();
//     }, 100)
//   }, [])

//   return (
//     <div className="contSlider bannerSize">
//       {slides}
//     </div>
//   );
// }

// function SlideItem(props){
//   const item = props.item;
//   const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

//   return (
//     <div className="contSlide bannerSize">
//       <img className="contSlide__img" src={bgUrl} alt="Banner" />
//     </div>
//   );

// }
import utils from 'utils';
import cmsUtils from 'cms/utils/cmsUtils';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function SliderItem(props){
    const item = props.item;
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    //console.log('item', item);
    //const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'sliderItem', item.cssClass || item.anchorName || ''); 
    return(
        <div css={style.sliderItem} className={cssClass}>
            <img src = {imageUrl} alt="image1"></img>
        </div>
    );
}
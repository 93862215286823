//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'


export default {
    
     pageTiles : css(mq({
          display: 'flex',
          justifyContent: ['center', 'center', 'flex-start'],
          //maxWidth : ['unset', '75%', '100%', '90%', 'unset'],
          flexWrap: 'wrap',
          margin: '2rem -10px',
          //border: '1px solid red'
     })), 
     // pageTiles : css({
     //      display: 'flex',
     //      justifyContent: 'center',
     //      flexWrap: 'wrap',
     //      margin: '2rem -10px'
     // }),
     pageTile__tile : css(mq({
         flexBasis: ['100%', '100%', '47%', '47%', '31.33%'],
         maxWidth : ['100%', '80%', '100%', '40%', '100%'],
         //maxWidth : '100%',
         position: 'relative',
         overflow : 'hidden',
         //border: '1px solid red',
         margin : '10px'
    })),

    tile__content : css({
          display : 'block',
          overflow : 'hidden',
         '&:hover' : {
            textDecoration: 'none',
          //   '& .pageTile__bsyc__content__details' : {
          //       backgroundColor : '#429b57'
          //   }
            '& .pageTile__adore__bg' : {
               //backgroundColor : '#429b57'
               transform: 'scale(1.05)'
           }
         }
    }),

    tile__bg : css(mq({
         height: '0',
         paddingTop: '95%',
         backgroundPosition: '50%',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         transition: '.5s'
         //flex: '1 1 300px',
    })),

    tile__details : css({
        backgroundColor : 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width : '70%',
        padding : '1.5rem 1rem',
        minHeight: '50%',
        color : '#333333'
    }),

    tile__details__title : css({
         marginBottom : '1rem',
         fontWeight: '700',
         fontSize : '1.3rem',
         color : '#0071bc'
    }),
    tile__details__desc : css(mq({
         fontSize : ['16px', '18px']
    }))

}
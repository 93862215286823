import React, { useLayoutEffect, useRef } from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { useRouter } from 'components';
import { handleElementLink } from 'components';
import env from 'env';

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  //console.log('item', item, cmsOption);

  let content = cmsOption.isCmsEdit? 
    cmsUtils.payload(item, 'Content'): 
    cmsUtils.payload(item, 'Content').replace("__version__", "<a href = '/app' class = 'app_version'> PWA "+ ver +"</a>");

  if (!cmsOption.isCmsEdit){
    //console.log(content)
    if (utils.site.isNZ){
      content = content.replace(/{"price":"B9000RA"}/g, 'RRP: NZ $499.99').replace(/{&quot;price&quot;:&quot;B9000RA&quot;}/g, 'RRP: NZ $499.99')
    } else {
      content = content.replace(/{"price":"B9000RA"}/g, 'RRP: AU $429.95').replace(/{&quot;price&quot;:&quot;B9000RA&quot;}/g, 'RRP: AU $429.95')
    }
  }
  const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) { 
    
    let target = "";
    if(e.target.closest('a')){
      target = e.target.closest('a').getAttribute('target');
    }
    const processed = target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target);
    //const link = utils.url.getLinkFromElement(e.target);

    if(target === "_blank"){
      processed.linkInfo.type = 'external';
    }

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();

    } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url)
    } else {
      // e.preventDefault();
      // e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))
    }
  }

  const htmlBlock = useRef(null);
  // function iframeSizer() {
  //   //console.log('iframesizer')
  //   let iframesContainer = htmlBlock.current.querySelectorAll('iframe.youtube, .ytvideo iframe');
  //   //console.log('iframesizer', iframesContainer)
  //   if (iframesContainer && iframesContainer.length) {
  //     iframesContainer.forEach(element => {
  //       let height = (element.parentElement.offsetWidth * 9) / 16
  //       //console.log(height, element.parentElement.offsetWidth)
  //       element.style.height = height + 'px';
  //     });
  //   }
  // }

  useLayoutEffect(() => {
    const buttons = htmlBlock.current.querySelectorAll('.button');
    for (let i = 0; buttons && i < buttons.length; i++) {
      restructureButton(buttons[i])
    }

    // const youtubes = htmlBlock.current.querySelectorAll('iframe[src*="youtube"]');
    // console.log(youtubes)

    // iframeSizer();
    // window.addEventListener('resize', iframeSizer)
    // return (): void => {
    //   window.removeEventListener('resize', iframeSizer)
    // }

  }, [])

  return (
    <div ref={htmlBlock} className={cssClass} data-cms-html="true"
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      dangerouslySetInnerHTML={{ __html: content }} onClick={(e) => handleClick(e)}></div>
  )
}


function restructureButton(el) {
  if (!el.querySelector('span')) {
    const span = document.createElement('span');
    span.innerHTML = el.innerHTML;
    el.innerHTML = '';
    el.appendChild(span);
  }
}
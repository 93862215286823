import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from 'components';
import { StoreProvider } from 'store';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
//import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
// import { BlogView } from './site/blog/blogView';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useParallax } from 'components';
import { AppVersionPage } from 'components';
import { MediaQueryStatus } from 'components';
import { CommonVideoPopup } from "./layout/popup/commonVideoPopup";
import { HelmetProvider } from 'react-helmet-async';

function PageUpdated() {
  const { pathname } = useRouter();
  const parallax = useParallax();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
    parallax.reset();
    // eslint-disable-next-line
  }, [pathname]);
  return null;
}

function AppLayout(props) {
  const { pathname } = useRouter();
  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  useScrollPosition(({ prevPos, currPos }) => {
    //console.log(currPos.x)
    //console.log(currPos.y);
    if (currPos.y > 90) {
      document.querySelector('body').classList.add('scrolled')
    } else {
      document.querySelector('body').classList.remove('scrolled')
    }
    //setScrolled(currPos.y > 90)
  }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'}`}>
      <PageUpdated />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>
            <Route path="/">
              <ViewPage />
            </Route>
          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <div style = {{ marginBottom : '2rem'}}></div>
      <BottomDock></BottomDock>
      <MediaQueryStatus />
      <CommonVideoPopup />
    </div>
  );
}

function App() {
  return (
    <StoreProvider>
      <HelmetProvider>
      <Router>
        <AppLayout />
      </Router>
      </HelmetProvider>
    </StoreProvider>
  );
}

export default App;

//declare var CKEDITOR: any;
//import { CmsPageController } from './cms-page-controller'

export class CmsRadioReceiver {
  controller;//: CmsPageController;
  constructor(controller) {
    this.controller = controller;
    this.init();
  }

  init() {
    var self = this;
    window.addEventListener("message", function (e) {

      var action = e.data.action;
      var data = e.data.data;

      // console.log(e, "cms-action-received: " + action, data);

      if (action === 'select-item') {
        self.selectItem(data.itemId)
      } else if (action === 'media-selected-ckeditor') {
        self.mediaSelectedCKEditor(data)
      } else if (action === 'get-all-dirty-html') {
        var htmlChanges = self.controller.getAllDirtyHtml();
        self.controller.sender.returnAllDirtyHtml(data.returnTo, htmlChanges);
      } else if (action === 'get-html') {
        var html = self.controller.getHtml(data.id);
        self.controller.sender.returnHtml(data.id, html);
      } else if (action === 'page-reload') {
        window.location.reload();
      }
    }, false);
  }

  mediaSelectedCKEditor(data) {
    const CKEDITOR = window['CKEDITOR'] || {};
    CKEDITOR.tools.callFunction(data.funcNum, data.selectedPath);
  }

  selectItem(itemId) {
    this.controller.selectItemFromController(itemId);
  }
}
